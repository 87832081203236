import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`CrossFit Open WOD 17.5`}</strong></p>
    <p>{`RX`}</p>
    <p>{`10 rounds for time of:\\
9 thrusters, 95 lb./65 lb.\\
35 double-unders`}</p>
    <p>{`Scaled`}</p>
    <p>{`10 rounds for time of:\\
9 thrusters, 65 lb./45 lb.\\
35 single-unders`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be judging 17.5 during class for unlimited members today as
well as 10-11am on Saturday at The Ville.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no CrossFit Kids this Saturday at East.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Starting next month we will no longer have a 6:30pm class on
Tuesday & Thursday at East due to low attendance.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      